import {Color} from "./Color.js";
const C = x => new Color(x);

export const Rose = [C(0x277256), C(0xA8D889), C(0xF34481), C(0xFCCBCA), C(0xFFEFF3)];
export const Watermelon = [C(0xc36864), C(0xff8482),  C(0xb6c363), C(0xcee44a), C(0xf1ffa0)];
export const Sable = [C(0xDAECF6), C(0xE7B961), C(0xBD7D33), C(0x9A5F3F), C(0x5A2C1D)];
export const Candy = [C(0xFFF7F8), C(0xFFA69E), C(0xFF7E6B), C(0x8C5E58), C(0xA9F0D1)];
export const Jungle = [C(0xe2e7d8), C(0xa7da63), C(0x614303), C(0xf58810), C(0xf24513)];
export const Savana = [C(0x3b5166), C(0xfff275), C(0xff8c42), C(0xff3c38), C(0xa23e48)];
export const Portal = [C(0xD5DBEB), C(0x0082C4), C(0x005DDF), C(0xF07716), C(0xEE7F1B)];
export const Peach = [C(0xF8F4D7), C(0xF4DEC2), C(0xF4B36C), C(0xE98977), C(0xF2B4A8)];
export const Poppies = [C(0xEDE8E2), C(0xC7D477), C(0x4A362A), C(0xCF1717), C(0xF97B5F), C(0xD3D3B2)];
export const Ocean = [C(0xE8DBD7), C(0xD4D8E7), C(0xD4D8E7), C(0x46A4C4), C(0x3066AC), C(0xC24540)];
export const Strawberry = [C(0x6BB053), C(0x185F27), C(0xCA233D), C(0xAE192C), C(0x6E202E)];
export const Pink = [C(0xF4EBBA), C(0xF4E0B5), C(0xF0B5B3), C(0xF6839C), C(0xFE64A3)];
export const Cobacabana = [C(0xA44C89), C(0xFF4B54), C(0xFF7F42), C(0xFEB13B)];
export const Banana = [C(0xD1FF79), C(0xFF2B3A), C(0x971C30), C(0x51132A)];
export const Marine = [C(0xF8F5E3) ,C(0x002746) , C(0x174E79), C(0xE57525), C(0x614735)];
export const Baobab = [C(0x86AC81), C(0xFFD2B2), C(0xD25A59), C(0x6A2E4D)];
export const Jeans = [C(0x407ba5), C(0x253447), C(0xe0634d), C(0x7e462b), C(0xfcfcfc)];
export const Blue = [C(0xedeef3), C(0x0000c0), C(0x333ea2), C(0x93b3ed), C(0x000095), C(0x155cbd), C(0x7db3fb), C(0x7db3fb)];
export const Flower = [C(0xFAC6D0), C(0xEF8C8D), C(0xFDF494), C(0x709255), C(0x416D05)];
export const Tired = [C(0xa76b81),  C(0xe9dde0), C(0xa093a6), C(0x67687e), C(0x4a535f)];
export const Sumer = [C(0xc8cf7f), C(0xeee099), C(0xf7efc2), C(0xfcbf89), C(C(0xff7280))];
export const Beach = [C(0xfffacc), C(0xffebba), C(0xffd0b5), C(0xffb2b2), C(0x6dc9c9), C(0xa5d8d8)]; //Oh my god
export const Apple = [C(0xc59570), C(0xd8b39a), C(0xefefc3), C(0xdee58a), C(0xc3e54a)];
export const Underwater = [C(0xd6f6b4), C(0xa9e899), C(0x44a682), C(0x467d7f), C(0x376d6d)];
export const Sunset = [C(0xffe054), C(0xfebc68), C(0xf18f86), C(0xc56d9d),  C(0x9967a4)];
export const Soft = [C(0xe1f4fa), C(0xc8edff), C(0xd2cffe), C(0xf0cafd), C(0xfdd8ea)];
export const Queen = [C(0xc01820), C(0xd83840), C(0xf05860), C(0x981060), C(0x700040)];
export const Decay = [C(0x504040), C(0x908088), C(0xb0a8b8), C(0xd8d0e0), C(0xc04850), C(0x803840)];
export const Used = [C(0xb1b26a), C(0x8d824a), C(0x63382d), C(0xd28455), C(0xf4d5a0)];
export const Purple = [C(0x6180D6), C(0x82A4FD), C(0xF4AFF1), C(0xBC7CDB), C(0x484374)];
export const SnowWhite = [C(0x681514), C(0x921911), C(0xC1E7F5), C(0x72CAF1), C(0x49B7EC)];
export const Sahara = [C(0x8B2A0E), C(0x993513), C(0xBB5026), C(0xF88339), C(0xA6C6F5)];
export const Island = [C(0x5B2A1F), C(0xBD7050), C(0xA5A570), C(0xC1DDF1), C(0x8BB2DB)];
export const StaryNight = [C(0x0F1724), C(0x12232D), C(0x222A37), C(0x394144), C(0x564D42)];
export const Playfull = [C(0xBB95B7),C(0xE4A4B0),C(0xFCC1AE),C(0xF2EEAE),C(0x6FC6CA)];
export const Pirate = [C(0x4E1E22), C(0x902D23), C(0x0D3F2D), C(0x051E33), C(0x000524)];
export const Vegas = [C(0x271A22),C(0x34222D),C(0x9F9CD9),C(0x7673C2),C(0x26223D)];
export const ModernArt = [C(0x364B6F), C(0x3E658E), C(0xB7AC9F), C(0x5A4E31), C(0x443F28)];
export const Sweden = [C(0x25778D), C(0x7DACC1), C(0xD69037), C(0xBD7632), C(0x8E512A)];
export const California = [C(0x38355B), C(0x70608B), C(0xD8678C), C(0xF37571), C(0xE99687)];
export const Moon = [C(0x051E3F), C(0x193056), C(0x445273), C(0x9A899A), C(0xB9A6AA)];
export const Nut = [C(0x7c6743), C(0xffea7c), C(0xd0db6b), C(0xa8bc58), C(0x88964e)];
export const Neon = [C(0xe032a0), C(0xee2afd), C(0xba3afc), C(0x7c62fc), C(0x5d8afc), C(0x50abfd)];
export const Atmospher = [C(0xF88FE8), C(0xFE75E1), C(0xEA31CD), C(0x701EA4), C(0x3D2190)];
export const Ice = [C(0x008BE3), C(0x3BA7F5), C(0x70BFFE), C(0x9CD1FF), C(0xBAE1FE)];
export const Emerald = [C(0x061B11),C(0x022601), C(0x265902), C(0x65A603), C(0x97D602)];
export const Geometric = [C(0x4A4759), C(0xD99484), C(0xF2BC8D), C(0xD9CC8B), C(0x0E0E0E)];
export const Road = [C(0xABA9D9), C(0xD2B7BC), C(0x94B7B8), C(0x44A69C), C(0x188C77)];
export const Blueberry = [C(0x1A243F),C(0x3C5880), C(0x7B97BE), C(0x836871), C(0x7B3438)];
export const Pride = [C(0x750787),C(0x004dff),C(0x008026),C(0xffed00),C(0xff8c00),C(0xe40303)];
export const YourName = [C(0x0C1030),C(0x085888), C(0x88CDE4), C(0x38478C), C(0x5D2C74), C(0x8E5D87), C(0x3B3C81)];
export const Urban = [C(0x70C3C7),C(0x7DB3CD), C(0x608099), C(0x728D7C), C(0xB4ADCC), C(0xDCC2A7)];
export const Estrella = [C(0x3A3462),C(0x4D577A),C(0x6E1E53),C(0xE8AB96),C(0xF3C3B9),C(0xEFA7BB)];
export const RPG = [C(0x7CB4D5),C(0x7FC8D1),C(0x294644),C(0x507446),C(0xD3BB8B),C(0x924440)];
export const Firewatch = [C(0xFFD5A3), C(0xFD9D33), C(0xB44633), C(0x6A202D), C(0x46152A), C(0x311126)];
export const GrandBudapest = [C(0x76504F), C(0xE29988), C(0xEB7D80), C(0xB98E87), C(0x514859), C(0x527A86)];
export const Sand = [C(0x5F6A91), C(0xD76A54), C(0xFCEEBC), C(0x3B3F5A), C(0xF6C749)];
export const Venon = [C(0x684266), C(0x442f56), C(0x333559), C(0x2a354c), C(0x434d60)];
export const Ceramique = [C(0x112625), C(0x445C56), C(0x889F8A), C(0xCCD6D1), C(0xD5CFC1), C(0xECE2DE)];
export const Cherry = [C(0xBA2F27), C(0x441825), C(0x6A89B1), C(0xC5C4D6), C(0xDDD7E3), C(0xEAE3E4)];
export const Wild = [C(0x687DB6), C(0x46425D),  C(0x515648), C(0xD58659), C(0xF2C285), C(0xE1CCDE)];
export const Towel = [C(0xD1EAAF), C(0xCED071), C(0xCED071), C(0xBC92A2), C(0xD8C3D1), C(0xC5C5C6)];
export const Lavender = [C(0xF6DBE0), C(0xE7EEE3), C(0xD3D3F2), C(0x7A7AD8), C(0x564FA7), C(0x705E8F)];
export const Cacti = [C(0xECE2DE),C(0xD5CFC1), C(0xCCD6D1), C(0x889F8A), C(0x445C56), C(0x112625)];
export const Succulent = [C(0xD58568), C(0xDCE0C3), C(0xBCD0B3), C(0x9CB6A8), C(0x577160), C(0x30402F)];
export const Violet = [C(0xF3F1EF), C(0xDAE2F8), C(0x7AB799),C(0x324942), C(0x593071),C(0xCA74BB)];
export const Dust = [C(0x687DB6),C(0x46425D),C(0x515648),C(0xD58659),C(0xF2C285),C(0xE1CCDE)];
export const Limon = [C(0x87B083), C(0x2E503D), C(0xDFAC5A), C(0xEBD175), C(0xF0EEA5), C(0xF1F9CF)];
export const Coton = [C(0x98B686), C(0x786A73), C(0xAB80B0), C(0xC6B0E4), C(0xDFCEF7), C(0xF1F0EC)];
export const Honey = [C(0xF7F6F3), C(0xEFECE6), C(0xEEC791), C(0xCB7046), C(0x624F48), C(0xBCA994)];
export const Holliday = [C(0xB2EEEE), C(0x7ED6DA), C(0x3682BC), C(0x243C5C), C(0x324E51), C(0xB8AC7F)];
export const Surreal = [C(0x7567B8), C(0x142235), C(0x31448F), C(0x468598), C(0x79C5C3), C(0xC5F7DE)];
export const Sweat = [C(0xB9D4F5), C(0x4E4689), C(0xD24B63), C(0xF4B698), C(0xEAE69E), C(0xE4E2E2)];
export const SummerSky = [C(0x3062B8),C(0x589FC0), C(0x3A7EC9), C(0x78C5DB), C(0xE2DED9), C(0xDCDCDC)];
export const Signal = [C(0x0A1D3B), C(0x132F56), C(0xE5376A), C(0xF87B9B), C(0xF2F9FF)];
export const Sugar = [C(0xF1E3D2), C(0xFE97BE), C(0xE65B51), C(0x2A5F9E), C(0x6FB882)];
